export default defineNuxtRouteMiddleware(async (to, from) => {
  const storeAuth = useAuth();

  await storeAuth.refresh();
  
  const localePath = useLocalePath();

  if (!storeAuth.isAuthenticated) {
   return navigateTo(localePath('/login'));
  }
});
